import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts";
import HeroGeneral from "../components/heroes/general";
import ServiceCard from "../components/service-card";

function Services({ data }) {
  return (
    <Layout title="Services">
      <HeroGeneral title="Services" />

      <div className="font-standard max-w-screen-desktop mb-8 md:mb-16 mx-auto px-6">
        <div className="mb-8 md:mb-0 text-xl">
          <div className="flex flex-wrap flex-col md:flex-row">
            {data.allSanityService.edges.map(service => (
              <div className="md:w-1/2 py-2 md:p-2" key={service.node._id}>
                <ServiceCard data={service.node} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanityService {
      edges {
        node {
          _id
          title
          slug {
            current
          }
          featureImage {
            asset {
              id
            }
          }
        }
      }
    }
  }
`;

export default Services;
