/**
 * Service Card
 * This component is used to display a service.
 */
import React from "react";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { projectId, dataset } from "../../utils/sanity";
import Heading from "../heading";
import Button from "../button";

const sanityConfig = { projectId, dataset };

function ServiceCard({ data }) {
  const imageAssetId = data.featureImage?.asset.id;
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxWidth: 1024 }, sanityConfig);

  return (
    <article className="flex flex-grow relative">
      {fluidProps && (
        <>
          <Img
            fluid={fluidProps}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: -1,
            }}
          />

          <div
            style={{
              background: "rgba(0, 0, 0, .75)",
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: 0,
            }}
          />
        </>
      )}

      <div className="flex-grow relative z-10 text-center px-4 md:px-0 py-18">
        <Heading className="text-5xl text-white mb-8">{data.title}</Heading>

        <div className="mx-4 md:mx-0">
          <Button variant="outlined" link={`/services/${data.slug.current}`}>
            Read More
          </Button>
        </div>
      </div>
    </article>
  );
}

export default ServiceCard;
